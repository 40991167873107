import { useMap } from "@vis.gl/react-google-maps";
import React from "react";
import { secondary } from "../../theme";

export interface PolylineProps {
  path: google.maps.LatLngLiteral[];
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  closed?: boolean;
  zIndex?: number;
}

function Polyline(props: PolylineProps) {
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[]>([]);

  const map = useMap();

  React.useEffect(() => {
    if (props.closed) {
      let p = props.path;
      if (p.length === 0) {
        setPath([]);
        return;
      }
      setPath([...p, p[0]]);
    } else {
      setPath(props.path);
    }
  }, [props.path]);

  React.useEffect(() => {
    const polyline = new google.maps.Polyline({
      path: path,
      geodesic: true,
      strokeColor: props.strokeColor ?? secondary,
      strokeOpacity: props.strokeOpacity ?? 1.0,
      strokeWeight: props.strokeWeight ?? 5,
      zIndex: props.zIndex,
      clickable: false,
    } as google.maps.PolylineOptions);
    polyline.setMap(map);

    return () => {
      polyline.setMap(null);
    };
  }, [map, path]);

  return null;
}

export default Polyline;
