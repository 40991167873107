import { AppBar, Autocomplete, Box, Button, Dialog, Divider, IconButton, InputAdornment, Paper, Slide, TextField, Toolbar, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import { t } from "i18next";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const colors = [
  'red',
  'orange',
  'yellow',
  'lightgreen',
  'green',
];

export interface ShapeFileDialogProps {
  nZones: number;
  open: boolean;
  onClose: () => void;
}

const products = [
  { label: 'Urea', value: 'urea' },
  { label: 'Ammonium Nitrate', value: 'an' },
  { label: 'Ammonium Sulfate', value: 'as' },
  { label: 'DAP', value: 'dap' },
  { label: 'MAP', value: 'map' },
  { label: 'Potassium Chloride', value: 'kcl' },
  { label: 'Potassium Sulfate', value: 'sulfate' },
  { label: 'Potassium Nitrate', value: 'nitrate' },
  { label: 'Monoammonium Phosphate', value: 'map' },
  { label: 'Micronutrients', value: 'micronutrients' },
];

interface ZoneRowProps {
  color: string;
}

function ZoneRow(props: ZoneRowProps) {
  const style = {
    paddingTop: '10px',
    paddingBottom: '10px',
  }

  return (
    <tr style={style}>
      <td style={{
        ...style,
        paddingRight: '10px',
      }}>
        <Paper style={{
          backgroundColor: props.color,
          width: '20px',
          height: '20px',
        }} />
      </td>
      <td style={{
        ...style,
        paddingRight: '10px',
        paddingLeft: '10px',
      }}>
        <TextField
          label={t("Dosage")}
          fullWidth
          type="number"
          defaultValue="50"
          variant="standard"
        />
      </td>
      <td style={{
        ...style,
        paddingLeft: '10px',
      }}>
        <Typography>0.5 ha</Typography>
      </td>
    </tr>
  );
}

function ShapeFileDialog(props: ShapeFileDialogProps) {
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={props.open}
        onClose={props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={props.onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {t('Export ShapeFile')}
            </Typography>
            <Button autoFocus color="inherit" onClick={props.onClose}>
              {t('Export')}
            </Button>
          </Toolbar>
        </AppBar>
        
        <Box style={{
          padding: '10px',
          paddingTop: '30px',
          paddingBottom: '30px',
        }} component="form">
          <Autocomplete
            fullWidth
            disablePortal
            options={products.map((option) => {
              option.label = t(option.label);
              return option;
            })}
            renderInput={(params) => <TextField {...params} label={t("Product")} />}
          />
          <TextField
          style={{ marginTop: '15px' }}
          label={t("Average Quantity")}
          fullWidth
          type="number"
          defaultValue="50"
          InputProps={{
            endAdornment: <InputAdornment position="end">kg/ha</InputAdornment>,
          }}
          />
        </Box>

        <Divider />

        <Box style={{
          padding: '10px',
        }}>
          <table>
            <thead>
              <tr style={{
                paddingTop: '10px',
                paddingBottom: '10px',
              }}>
                <th style={{
                  paddingRight: '10px',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}></th>
                <th style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}>{t('Dosage')}</th>
                <th style={{
                  paddingLeft: '10px',
                  paddingBottom: '10px',
                  paddingTop: '10px',
                }}>{t('Extension')}</th>
              </tr>
            </thead>
            <tbody>
              { Array.from({ length: props.nZones }, (_, i) => (
                <ZoneRow key={i} color={colors[i]} />
              )) }
            </tbody>
          </table>
        </Box>

        <Button
        style={{
          margin: '10px',
          width: 'calc(100% - 20px)',
        }}
        variant="contained"
        color="primary"
        onClick={props.onClose}
        >
          {t('Export')}
        </Button>

      </Dialog>
    </React.Fragment>
  );
}

export default ShapeFileDialog;
