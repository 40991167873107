import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FieldDataItem } from "../classes/field_data_item";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { gql, useMutation } from "@apollo/client";

const availableCultures = [
  "Corn",
  "Barley",
  "Wheat",
  "Soybean",
  "Meadow",
  "Vineyard",
];

const availablePhenologicalPhases: Record<string, string[]> = {
  "default": [
    "Emergence",
    "Rising",
    "Flowering",
    "Ripening",
  ],
  "Vineyard": [
    "Vegetative",
    "Flowering",
    "Fruit Setting",
    "Veraison/Maturation",
  ],
}

const getAvailablePhenologicalPhases = (culture: string | null) => {
  if (culture) {
    return availablePhenologicalPhases[culture] ?? availablePhenologicalPhases.default;
  }
  return availablePhenologicalPhases.default;
}

export interface FieldInfoDialogProps {
  open: boolean;
  onClose: () => void;
  field?: {
    id: number;
    name: string;
  } | null;
  agricultureAnalysisData?: {
    id: number;
    phase: string;
  };
  fieldDataItems?: FieldDataItem[];
  photogrammetry? : boolean;
}

const FieldInfoDialog = (props: FieldInfoDialogProps) => {
  const [ seedDate, setSeedDate ] = React.useState<Dayjs | null>(null);
  const [ culture, setCulture ] = React.useState<string | null>(null);
  const [ phase, setPhase ] = React.useState<string | null>(props.agricultureAnalysisData?.phase || null);

  const { t } = useTranslation();

  const [ insertSeedDate ] = useMutation(gql(`
    mutation InsertSeedDate($field_id: bigint!, $value: String!) {
      insert_field_data_items(objects: {
        field_id: $field_id,
        type: "Seed Date",
        value: $value,
      }) {
        returning {
          id
        }
      }
    }
  `), {
    refetchQueries: ['FieldDetails'],
  });

  const [ insertCulture ] = useMutation(gql(`
    mutation InsertCulture($field_id: bigint!, $value: String!) {
      insert_field_data_items(objects: {
        field_id: $field_id,
        type: "Culture",
        value: $value,
      }) {
        returning {
          id
        }
      }
    }
  `), {
    refetchQueries: ['FieldDetails'],
  });

  const [ updatePhenologicalPhase ] = useMutation(gql(`
    mutation UpdatePhenologicalPhase($agriculture_analysis_data_id: bigint!, $phase: String!) {
      update_agriculture_analysis_data_by_pk(pk_columns: {
        id: $agriculture_analysis_data_id,
      }, _set: {
        phase: $phase,
      }) {
        id
      }
    }
  `), {
    refetchQueries: ['FieldDetails'],
  });

  React.useEffect(() => {
    if (props.fieldDataItems) {
      let seedFound = false;
      let cultureFound = false;
      for (const item of props.fieldDataItems) {
        if (!seedFound && item.type === 'Seed Date') {
          setSeedDate(dayjs(item.value));
          seedFound = true;
        } else if (!cultureFound && item.type === 'Culture') {
          setCulture(item.value);
          cultureFound = true;
        }
      }
    }
  }, [ props.fieldDataItems ]);

  React.useEffect(() => {
    if (props.agricultureAnalysisData) {
      setPhase(props.agricultureAnalysisData.phase);
    }
  }, [ props.agricultureAnalysisData ]);

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            props.onClose();
          },
        }}
      >
        <DialogTitle>{t('Info')}</DialogTitle>
        <DialogContent>
          <table style={{
            width: '100%',
            borderCollapse: 'collapse',
          }}>
            <tbody>
              <tr>
                <th style={{
                  textAlign: 'right',
                  padding: '8px',
                }}>{t('Name')}</th>
                <td style={{
                  padding: '8px',
                }}>{props.field?.name}</td>
              </tr>
              { !props.photogrammetry && <tr>
                <th style={{
                  textAlign: 'right',
                  padding: '8px',
                }}>{t('Seed Date')}</th>
                <td style={{
                  padding: '8px',
                }}>
                  <DatePicker
                    sx={{
                      width: '100%',
                    }}
                    value={seedDate}
                    onChange={async (newValue) => {
                      const date = newValue?.toISOString();
                      if (!date || date === seedDate?.toISOString()) {
                        return;
                      }

                      await insertSeedDate({
                        variables: {
                          field_id: props.field!.id,
                          value: date,
                        },
                      });
                      setSeedDate(newValue);
                    }}
                  />
                </td>
              </tr> }
              { !props.photogrammetry && <tr>
                <th style={{
                  textAlign: 'right',
                  padding: '8px',
                }}>{t('Culture')}</th>
                <td style={{
                  padding: '8px',
                }}>
                  <FormControl fullWidth>
                    <Select
                      value={culture ?? ''}
                      onChange={async (event) => {
                        const value = event.target.value;
                        if (!value || value === culture) {
                          return;
                        }

                        await insertCulture({
                          variables: {
                            field_id: props.field!.id,
                            value: value,
                          },
                        });
                        setCulture(value);
                      }}
                    >
                      <MenuItem value={''}><em>{t('None')}</em></MenuItem>
                      {availableCultures.map((culture) => (
                        <MenuItem key={culture} value={culture}>{t(culture)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr> }
              { !props.photogrammetry && props.agricultureAnalysisData && <tr>
                <th style={{
                  textAlign: 'right',
                  padding: '8px',
                }}>{t('Phenological Phase')}</th>
                <td style={{
                  padding: '8px',
                }}>
                  <FormControl fullWidth>
                    <Select
                      value={phase ?? ''}
                      onChange={async (event) => {
                        const value = event.target.value;
                        if (value === phase) {
                          return;
                        }

                        await updatePhenologicalPhase({
                          variables: {
                            agriculture_analysis_data_id: props.agricultureAnalysisData?.id,
                            phase: value,
                          },
                        });
                        setPhase(value);
                      }}
                    >
                      <MenuItem value={''}><em>{t('None')}</em></MenuItem>
                      {getAvailablePhenologicalPhases(culture).map((phase) => (
                        <MenuItem key={phase} value={phase}>{t(phase)}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </td>
              </tr> }
            </tbody>
          </table>
        </DialogContent>
        <DialogActions>
          <Button type="submit">{t('Ok')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default FieldInfoDialog;
