import React from 'react';
import TimeLine from './TimeLine';
import CardButton from '../common/components/CardButton';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import ActionsDialog from '../dialogs/ActionsDialog';
import { primary } from '../theme';
import useCache, { IMAGE_NDREI_TIFF, IMAGE_NDREI_VINEYARD_TIFF, IMAGE_NDVI_TIFF, IMAGE_NDVI_VINEYARD_TIFF, IMAGE_ORTHOPHOTO } from '../common/hooks/useCache';
import JSZip from 'jszip';

export interface FloatingBottomBarProps {
  analysis: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      orthophoto_png: string,
      phase: string,
      index_data: {
        name: string,
        png: string,
        tiff: string,
        average: number,
        min: number,
        max: number,
        std_dev: number,
      }[],
    }[],
  }[];
  currentAnalysis?: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      phase: string,
    }[],
  };
  selectedIndex?: string;
  setCurrentAnalysis: (analysis: any) => void;
  setZonationDialogOpen: (open: boolean) => void;
  zonationAvailable?: boolean;
}

function FloatingBottomBar(props: FloatingBottomBarProps) {
  const [ actionsDialogOpen, setActionsDialogOpen ] = React.useState<boolean>(false);

  const { downloadPresigned } = useCache();

  const style: React.CSSProperties = {
    position: 'absolute',
    bottom: '25px',
    left: 75,
    right: 8,
    height: 55,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  };

  const downloadMap = async () => {
    if (!props.currentAnalysis) return;

    let orthophoto: Blob | null = null;
    let ndvi: Blob | null = null;
    let ndvi_vineyard: Blob | null = null;
    let ndrei: Blob | null = null;
    let ndrei_vineyard: Blob | null = null;

    try {
      orthophoto = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_ORTHOPHOTO);
    } catch (error) {
      console.error(error);
    }
    try {
      ndvi = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_NDVI_TIFF);
    } catch (error) {
      console.error(error);
    }
    try {
      ndvi_vineyard = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_NDVI_VINEYARD_TIFF);
    } catch (error) {
      console.warn(error);
    }
    try {
      ndrei = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_NDREI_TIFF);
    } catch (error) {
      console.error(error);
    }
    try {
      ndrei_vineyard = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_NDREI_VINEYARD_TIFF);
    } catch (error) {
      console.warn(error);
    }

    const zip = new JSZip();
    orthophoto && zip.file('orthophoto.tif', orthophoto);
    ndvi && zip.file('ndvi.tif', ndvi);
    ndvi_vineyard && zip.file('ndvi_vineyard.tif', ndvi_vineyard);
    ndrei && zip.file('ndrei.tif', ndrei);
    ndrei_vineyard && zip.file('ndrei_vineyard.tif', ndrei_vineyard);

    const blob = await zip.generateAsync({ type: 'blob' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${props.currentAnalysis.uuid}.zip`;
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} style={style}>
        <TimeLine
          analysis={props.analysis}
          currentAnalysis={props.currentAnalysis}
          currentIndex={props.selectedIndex}
          onAnalysisSelected={(analysisUuid) => {
            props.setCurrentAnalysis(props.analysis.find((a) => a.uuid === analysisUuid) ?? null);
            if (analysisUuid) {
              const url = new URL(window.location.href);
              url.searchParams.set('analysis', analysisUuid);
              window.history.replaceState({}, document.title, url.toString());
            }
          }}
          contained
        />
        <CardButton
          text={t('Actions')}
          style={{
            width: 'calc((100% - 8px) / 2)',
            height: '100%',
            backgroundColor: actionsDialogOpen ? primary : undefined,
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          iconButtonStyle={{
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          showMore={!actionsDialogOpen}
          showLess={actionsDialogOpen}
          onClick={() => setActionsDialogOpen(true)}
        />
      </Stack>
      <ActionsDialog
        zonationAvailable={props.zonationAvailable}
        setZonationDialogOpen={props.setZonationDialogOpen}
        open={actionsDialogOpen}
        onClose={() => setActionsDialogOpen(false)}
        downloadMap={async () => {
          await downloadMap();
          setActionsDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
}

export default FloatingBottomBar;
