import React from "react";
import { numberFormat } from "../utils/text";
import { Slider, SliderThumb, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import './IndexSlider.css';

export interface IndexSliderProps {
  min?: number;
  max?: number;
  onSelectedRange?: (range: [number, number]) => void;
  reversed?: boolean;
  top?: number | string;
  bottom?: number | string;
  splitColor?: boolean;
  marks?: { value: number, label: string }[];
  showLabels?: boolean;
}

const ColorSlider = styled(Slider)(({ theme }) => ({
  color: 'transparent',
  width: '25px',
  height: '100%',
  padding: '0 !important',
  '& .MuiSlider-thumb': {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .color-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-rail': {
    color: 'transparent',
  },
}));

interface ColorThumbComponentProps extends React.HTMLAttributes<unknown> {}

function ColorThumbComponent(props: ColorThumbComponentProps) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
      <span className="color-bar" />
      <span className="color-bar" />
      <span className="color-bar" />
    </SliderThumb>
  );
}

function IndexSlider(props: IndexSliderProps) {
  const [value, setValue] = React.useState<number[] | null>(null);

  const { t } = useTranslation();

  const preventHorizontalKeyboardNavigation = (event: React.KeyboardEvent) => {
    if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
      event.preventDefault();
    }
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
    if (props.onSelectedRange && Array.isArray(newValue)) {
      props.onSelectedRange(newValue as [number, number]);
    }
  };

  React.useEffect(() => {
    if (props.min !== undefined && props.max !== undefined) {
      setValue([props.min, props.max]);
    } else {
      setValue(null);
    }
  }, [props.min, props.max]);

  if (props.min === undefined || props.max === undefined || value === null) {
    return null;
  }

  // 5 steps from min to max
  const marks = props.marks ?? [
    {
      value: props.min,
      label: numberFormat(props.min, 2),
    },
    {
      value: props.min + (props.max - props.min) / 4,
      label: '',
    },
    {
      value: props.min + (props.max - props.min) / 2,
      label: '',
    },
    {
      value: props.min + (props.max - props.min) * 3 / 4,
      label: '',
    },
    {
      value: props.max,
      label: numberFormat(props.max, 2),
    },
  ];

  return (
    <div style={{
      position: 'absolute',
      top: props.top ?? '90px',
      bottom: props.bottom ?? '170px',
      right: '10px',
      width: '25px',
      background: props.reversed
        ?
        props.splitColor ? 'linear-gradient(0deg, hsl(120, 100%, 50%) 50%, hsl(0, 100%, 50%) 100%)' : 'linear-gradient(0deg, hsl(120, 100%, 50%) 0%, hsl(0, 100%, 50%) 100%)'
        :
        props.splitColor ? 'linear-gradient(0deg, hsl(0, 100%, 50%) 50%, hsl(120, 100%, 50%) 100%)' : 'linear-gradient(0deg, hsl(0, 100%, 50%) 0%, hsl(120, 100%, 50%) 100%)',
      borderRadius: '5px',
    }}>
      { /* <span style={{
        position: 'absolute',
        marginTop: '15px',
        left: '0',
        textAlign: 'right',
        fontSize: '15px',
        color: 'black',
        transform: 'rotate(-90deg)',
        width: '100%',
        lineHeight: '100%',
      }}>
        { numberFormat(props.max, 2) }
      </span>
      <span style={{
        position: 'absolute',
        marginBottom: '15px',
        bottom: '0',
        left: '0',
        fontSize: '15px',
        color: 'white',
        transform: 'rotate(-90deg)',
        width: '100%',
        lineHeight: '100%',
      }}>
        { numberFormat(props.min, 2) }
      </span> */ }
      <ColorSlider
        sx={{
          marginTop: '0',
          marginBottom: '0',
          marginLeft: '0',
          marginRight: '0',
          '& input[type="range"]': {
            WebkitAppearance: 'slider-vertical',
          },
        }}
        orientation="vertical"
        slots={{ thumb: ColorThumbComponent }}
        onChange={handleChange}
        value={value}
        min={props.min}
        max={props.max}
        step={0.01}
        valueLabelFormat={(value) => numberFormat(value, 2)}
        onKeyDown={preventHorizontalKeyboardNavigation}
        valueLabelDisplay="auto"
        marks={marks}
      />
      { props.showLabels && <React.Fragment>
        <div style={{
          position: 'absolute',
          top: 'calc(15%)',
          left: '-65px',
          textAlign: 'center',
          fontSize: '15px',
          color: 'white',
          transform: 'rotate(-90deg)',
          width: '100px'
        }}>
          { t('Healthy') }
        </div>
        <div style={{
          position: 'absolute',
          top: 'calc(65%)',
          left: '-65px',
          textAlign: 'center',
          fontSize: '15px',
          color: 'white',
          transform: 'rotate(-90deg)',
          width: '100px'
        }}>
          { t('Stressed') }
        </div>
      </React.Fragment> }
    </div>
  );
}

export default IndexSlider;
