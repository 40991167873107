import React from "react";
import { Button, Typography, Container } from "@mui/material";
import useUser from "../hooks/useUser";
import { useTranslation } from "react-i18next";
import { handleJoinList } from "../utils/waitingList";

export interface AcquireFeatureProps {
  name?: string;
  minRole?: string;
}

const AcquireFeature = (props: AcquireFeatureProps) => {
  const { authenticatedUserEmail } = useUser();

  const { t } = useTranslation();

  return (
    <Container style={{ textAlign: "center", marginTop: "20px" }}>
      <Typography variant="h5" gutterBottom>
        {t("Join the waiting list.")}
      </Typography>
      <Typography variant="body1" gutterBottom>
        {t("Please join the waiting list to be notified when this feature is available for you.")}
      </Typography>
      <Button
        style={{ marginTop: "20px" }}
        variant="contained" color="primary" onClick={() => {
          handleJoinList(
            props.name,
            props.minRole,
            authenticatedUserEmail,
            t("You have successfully joined the waiting list."),
            t("Failed to join the waiting list. Please try again later.")
          );
        }}>
        {t("Join Now")}
      </Button>
    </Container>
  );
};

export default AcquireFeature;
