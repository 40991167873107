import React from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import MapFull from "../common/components/MapFull";
import UserPosition from "../common/components/UserPosition";
import BackButton from "../common/components/BackButton";
import FloatingTopBar from "../components/FloatingTopBar";
import Annotate from "../common/dialogs/Annotate";
import { Annotation, Attachment } from "../common/classes/annotation";
import AnnotationMarker from "../common/components/AnnotationMarker";
import { Fab } from "@mui/material";
import Zonation from "../dialogs/Zonation";
import { useNavigate, useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { computeZoom } from "../common/utils/position";
import Loading from "../common/components/Loading";
import { and, whereNotDeleted } from "../common/utils/graphql";
import AnalysisLayer from "../common/components/AnalysisLayer";
import { db } from "../common/db/db";
import IndexSlider from "../common/components/IndexSlider";
import { getIndex, globalAvailableIndexes } from "../utils/indexes";
import Polyline from "../common/components/Polyline";
import Viewer3D from "../common/components/Viewer3D";
import useEmbed from "../common/hooks/useEmbed";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FieldInfoDialog from "../common/dialogs/FieldInfoDialog";
import { FieldDataItem } from "../common/classes/field_data_item";
import { getMarksForPhase } from "../utils/phases";
import FloatingBottomBar from "../components/FloatingBottomBar";
import { primary } from "../theme";

function FieldDetails() {
  const [ latLngs, setLatLngs ] = React.useState<google.maps.LatLngLiteral[]>([]);
  const [ selectedRange, setSelectedRange ] = React.useState<[number, number] | undefined>(undefined);
  const [ min, setMin ] = React.useState<number | undefined>(-1);
  const [ max, setMax ] = React.useState<number | undefined>(1);
  const [ zonationDialogOpen, setZonationDialogOpen ] = React.useState<boolean>(false);
  const [ infoDialogOpen, setInfoDialogOpen ] = React.useState<boolean>(false);
  const [ annotateDialogOpen, setAnnotateDialogOpen ] = React.useState<boolean>(false);
  const [ clickLatLng, setClickLatLng ] = React.useState<google.maps.LatLngLiteral | null>(null);
  const [ annotations, setAnnotations ] = React.useState<Annotation[]>([]);
  const [ fieldDataItems, setFieldDataItems ] = React.useState<FieldDataItem[]>([]);
  const [ availableIndexes, setAvailableIndexes ] = React.useState<string[]>([]);
  const [ selectedIndex, setSelectedIndex ] = React.useState<string | undefined>();
  const [ marks, setMarks ] = React.useState<{ value: number, label: string }[] | undefined>(undefined);
  const [ currentAnalysis, setCurrentAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      phase: string,
    }[],
  } | null>(null);
  const [ currentAnalysisData, setCurrentAnalysisData ] = React.useState<{
    id: number,
    phase: string,
  } | null>(null);
  const [ analysis, setAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      orthophoto_png: string,
      phase: string,
      index_data: {
        name: string,
        png: string,
        tiff: string,
        average: number,
        min: number,
        max: number,
        std_dev: number,
      }[],
    }[],
  }[]>([]);
  const [ field, setField ] = React.useState<{
    id: number,
    uuid: string,
    name: string,
  } | null>(null);
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();

  const url = new URL(window.location.href);
  const analysisUUID = url.searchParams.get('analysis');
  const indexName = url.searchParams.get('index');

  if (indexName && !selectedIndex) {
    setSelectedIndex(indexName);
  }

  const { fieldId } = useParams();

  const { loading, error, data } = useQuery(gql(`
    query FieldDetails($fieldId: uuid!) {
      fields(where: {uuid: {_eq: $fieldId}}) {
        id
        uuid
        name
        field_data_items(order_by: {created_at: desc}) {
          id
          created_at
          type
          value
        }
        analyses(where: `+and(whereNotDeleted() + '{type: {_eq: "agriculture"}}')+`, order_by: {created_at: desc}) {
          uuid
          created_at
          agriculture_analysis_data {
            id
            orthophoto
            orthophoto_png
            phase
            index_data(order_by: {name: asc}) {
              name
              png
              tiff
              average
              min
              max
              std_dev
            }
          }
        }
        lat_lngs(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          lat
          lng
        }
        annotations(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          uuid
          created_at
          annotation
          position(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
            lat
            lng
          }
          attachments(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
            uuid
            created_at
            file_name
            file_size
            file_type
          }
        }
      }
    }
  `), {
    variables: {
      fieldId,
    }
  });

  const [ deleteAnnotation ] = useMutation(gql(`
    mutation DeleteAnnotation($uuid: uuid!) {
      update_annotations(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        affected_rows
      }
    }
  `));

  React.useEffect(() => {
    setSelectedRange(undefined);
  }, [selectedIndex]);

  React.useEffect(() => {
    if (data) {
      setField(data.fields[0]);
      setAnnotations(data.fields[0].annotations.filter((a: any) => a.position.length > 0).map((a: any) => {
        return {
          uuid: a.uuid,
          date: new Date(a.created_at),
          position: { lat: a.position[0].lat, lng: a.position[0].lng },
          annotation: a.annotation,
          audioBlobs: [],
          pictureBlobs: [],
          attachments: a.attachments.map((att: any) => {
            return {
              uuid: att.uuid,
              createdAt: new Date(att.created_at),
              fileName: att.file_name,
              contentType: att.file_type,
            }
          }).sort((a: Attachment, b: Attachment) => a.createdAt.getTime() - b.createdAt.getTime()),
        } as Annotation;
      }));
      setFieldDataItems(data.fields[0].field_data_items);
      setAnalysis(data.fields[0].analyses);
      let currAnalysis: any | null;
      if (!currentAnalysis) {
        currAnalysis = data.fields[0].analyses.find((a: any) => a.uuid === analysisUUID) ?? data.fields[0].analyses[0];
        setCurrentAnalysis(currAnalysis);
        if (currAnalysis) {
          const url = new URL(window.location.href);
          url.searchParams.set('analysis', currAnalysis.uuid);
          window.history.replaceState({}, document.title, url.toString());
        }
      } else {
        currAnalysis = data.fields[0].analyses.find((a: any) => a.uuid === currentAnalysis.uuid) ?? data.fields[0].analyses[0];
        setCurrentAnalysis(currAnalysis);
      }

      const b = new google.maps.LatLngBounds();
      const tmpLatLngs: google.maps.LatLngLiteral[] = [];
      data.fields[0].lat_lngs.forEach((latLng: any) => {
        b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        tmpLatLngs.push({ lat: latLng.lat, lng: latLng.lng });
      });
      setDefaultCenter(b.getCenter().toJSON());
      setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
      setLatLngs(tmpLatLngs);

      db.fields.put({
        id: data.fields[0].uuid,
        bounds: b.toJSON(),
      }).then(() => {});

      const aIdx = [];
      if (data.fields[0].analyses.length > 0) {
        const tmp = currAnalysis.agriculture_analysis_data;
        if (tmp && tmp.length > 0 && (tmp[0].orthophoto || tmp[0].orthophoto_png)) {
          aIdx.push('Orthophoto');
        }
        if (tmp && tmp.length > 0 && tmp[0].index_data) {
          for (const idx of tmp[0].index_data) {
            aIdx.push(idx.name.toUpperCase());
          }
        }
      }
      setAvailableIndexes(aIdx);
    } else {
      setLatLngs([]);
      setField(null);
      setAnalysis([]);
      setFieldDataItems([]);
      setCurrentAnalysis(null);
      setAvailableIndexes([]);
      setDefaultCenter(undefined);
      setDefaultZoom(undefined);
    }
  }, [data, currentAnalysis, analysisUUID]);

  React.useEffect(() => {
    setCurrentAnalysisData(currentAnalysis?.agriculture_analysis_data[0] ?? null);
  }, [currentAnalysis]);

  React.useEffect(() => {
    const phase = currentAnalysisData?.phase;
    setMarks(getMarksForPhase(phase));
  }, [currentAnalysisData]);

  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  const onAnnotateDialogClose = (annotation: Annotation | undefined) => {
    if (annotation && clickLatLng) {
      annotation.uuid = annotations.length+'';
      annotation.position = clickLatLng;
      annotation.date = new Date();
      setAnnotations([...annotations, annotation]);
    }
    setAnnotateDialogOpen(false);
  }

  const onBoundsChange = (bounds: google.maps.LatLngBounds) => {
    if (!currentAnalysis) {
      return;
    }

    db.analyses.put({
      id: currentAnalysis.uuid,
      bounds: bounds.toJSON(),
    }).then(() => {});
  }

  if (!defaultCenter) {
    return (
      <Loading open />
    );
  }

  return (
    <MapFull
    defaultCenter={defaultCenter}
    defaultZoom={defaultZoom}
    onClick={(e) => {
      setClickLatLng(e.detail.latLng);
      setAnnotateDialogOpen(true);
    }}>
      { /* <Polygon path={latLngs} zIndex={-100} /> */ }
      { globalAvailableIndexes.map((gIndex) => {
        if (!currentAnalysis || selectedIndex !== gIndex.id || !gIndex.imageKey) {
          return null;
        }
        if (gIndex.id === 'Orthophoto') {
          return (
            <AnalysisLayer key={gIndex.id} analysisID={currentAnalysis.uuid} image={gIndex.imageKey} onBoundsChange={onBoundsChange} rgb />
          )
        }
        if (gIndex.id === 'GLB') {
          return (
            <Viewer3D key={gIndex.id}
              analysisID={currentAnalysis.uuid}
              image={gIndex.imageKey} />
          )
        }
        return (
          <AnalysisLayer key={gIndex.id}
            analysisID={currentAnalysis.uuid}
            image={gIndex.imageKey}
            onBoundsChange={onBoundsChange}
            // setMin={setMin}
            // setMax={setMax}
            range={selectedRange}
            clipMin={-1}
            clipMax={1}
            rangeMin={0}
            absolute />
        )
      }) }
      <Polyline path={latLngs} zIndex={100} closed />
      <UserPosition />
      <FloatingTopBar
        selectedIndex={getIndex(selectedIndex)}
        availableIndexes={availableIndexes}
        onIndexChange={(index) => {
          setSelectedIndex(index.id);
          if (index && index.id !== 'GLB') {
            const url = new URL(window.location.href);
            url.searchParams.set('index', index.id);
            window.history.replaceState({}, document.title, url.toString());
          }
        }}
        currentAnalysisUUID={currentAnalysis?.uuid}
        currentIndex={selectedIndex ?? undefined}
        showCompare />
      <BackButton />
      <Annotate open={annotateDialogOpen} onClose={onAnnotateDialogClose} latLng={clickLatLng} fieldID={field?.id ?? 0} />
      { annotations.map((annotation) => (
        <AnnotationMarker key={annotation.uuid} annotation={annotation} onDelete={() => {
          deleteAnnotation({
            variables: {
              uuid: annotation.uuid,
            }
          }).then(() => {
            setAnnotations(annotations.filter((a) => a.uuid !== annotation.uuid));
          }).catch((e) => {
            console.error("Failed to delete annotation", e);
          });
        }} />
      ))}
      {/* { selectedIndex && selectedIndex !== 'Orthophoto' && selectedIndex !== 'None' && <Fab
      onClick={() => setZonationDialogOpen(true)}
      style={{
        position: 'absolute',
        bottom: '155px',
        right: '10px',
        zIndex: 0,
      }} color="primary">
        <ExtensionIcon />
      </Fab> } */}
      <Fab
      onClick={() => setInfoDialogOpen(true)}
      style={{
        position: 'absolute',
        bottom: '25px',
        left: '10px',
        backgroundColor: infoDialogOpen ? primary : undefined,
        color: infoDialogOpen ? 'white' : undefined,
        zIndex: 0,
      }} color="inherit">
        <InfoOutlinedIcon />
      </Fab>
      { selectedIndex && selectedIndex !== 'Orthophoto' && selectedIndex !== 'GLB' && selectedIndex !== 'None' &&
        <IndexSlider
          splitColor
          min={min}
          max={max}
          bottom='105px'
          onSelectedRange={(range) => {
            setSelectedRange(range);
          }}
          marks={marks}
          showLabels={marks && marks.length > 0}
        />
      }
      <FloatingBottomBar
        analysis={analysis}
        currentAnalysis={currentAnalysis ?? undefined}
        selectedIndex={selectedIndex}
        setCurrentAnalysis={setCurrentAnalysis}
        zonationAvailable={!!selectedIndex && selectedIndex !== 'Orthophoto' && selectedIndex !== 'GLB' && selectedIndex !== 'None'}
        setZonationDialogOpen={setZonationDialogOpen}
      />
      <Zonation open={zonationDialogOpen} onClose={(nZones) => {
        setZonationDialogOpen(false);
        if (nZones) {
          navigate(appendParams('./zonation/' + selectedIndex));
        }
      }} />
      <FieldInfoDialog
        open={infoDialogOpen}
        onClose={() => setInfoDialogOpen(false)}
        field={field}
        fieldDataItems={fieldDataItems}
        agricultureAnalysisData={currentAnalysisData ?? undefined}
      />
    </MapFull>
  );
}

export default withAuthenticationRequired(FieldDetails);
