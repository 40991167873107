import { Coordinates } from "../grpc/common_pb";

export function geolocationToLatLng(geolocation: GeolocationPosition): google.maps.LatLngLiteral {
  return {
    lat: geolocation.coords.latitude,
    lng: geolocation.coords.longitude,
  };
}

export function coordinatesToLatLng(coordinates: Coordinates.AsObject): google.maps.LatLngLiteral {
  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };
}

export function computeZoom(bounds: google.maps.LatLngBounds, width: number, height: number): number {
  const GLOBE_WIDTH = 256; // a constant in Google's map projection
  const west = bounds.getSouthWest().lng();
  const east = bounds.getNorthEast().lng();
  let angle = east - west;
  if (angle < 0) {
    angle += 360;
  }
  const wZoom = Math.log(width * 360 / angle / GLOBE_WIDTH) / Math.LN2;

  const north = bounds.getNorthEast().lat();
  const south = bounds.getSouthWest().lat();
  angle = north - south;
  if (angle < 0) {
    angle += 360;
  }
  const hZoom = Math.log(height * 360 / angle / GLOBE_WIDTH) / Math.LN2;

  return Math.min(wZoom, hZoom) - 1;
}
