import { gql, useQuery } from '@apollo/client';
import { Divider, List } from '@mui/material';
import React from 'react';
import { withAuthenticationRequired } from 'react-oidc-context';
import { and, whereNotDeleted, whereOrganization } from '../common/utils/graphql';
import { OrganizationContext } from '../common/contexts/organization';
import FieldRow from '../components/FieldRow';
import FieldsTabs, { ListTab, MapTab } from '../common/components/FieldsTabs';
import MapAllFields from '../common/components/MapAllFields';
import Loading from '../common/components/Loading';

function Fields() {
  const [selectedTab, setSelectedTab] = React.useState<string>();
  const [fields, setFields] = React.useState<{
    uuid: string;
    name: string;
  }[]>([]);

  const organizationCtx = React.useContext(OrganizationContext);

  const { loading, error, data } = useQuery(gql(`
    query Fields {
      fields(where: `+and(whereNotDeleted(), whereOrganization(organizationCtx?.organizationID))+`, order_by: {created_at: desc}) {
        uuid
        name
        analyses(
          limit: 1,
          where: {agriculture_analysis_data: {orthophoto_png: {_neq: ""}}},
          order_by: {created_at: desc}
        ) {
          uuid
          state
          created_at
          agriculture_analysis_data {
            orthophoto_png
          }
        }
        lat_lngs(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          lat
          lng
        }
      }
    }
  `));

  React.useEffect(() => {
    if (data) {
      setFields(data.fields);
    }
  }, [data]);

  if (loading) {
    return <Loading open />;
  }

  return (
    <React.Fragment>
      <FieldsTabs onSelectedTab={(tab) => {
        setSelectedTab(tab);
      }}/>
      { selectedTab === MapTab && <MapAllFields fields={fields} pathPrefix='/app/fields' /> }
      { selectedTab === ListTab && <List sx={{
        width: '100%',
        maxWidth: '960px',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}>
        {fields.map((field, index) => <React.Fragment key={field.uuid}>
          <FieldRow field={field} />
          { index < fields.length - 1 && <Divider variant="inset" component="li" /> }
        </React.Fragment>)}
      </List> }
    </React.Fragment>
  );
}

export default withAuthenticationRequired(Fields);
