import React from "react";
import { Marker } from "@vis.gl/react-google-maps";
import { geolocationToLatLng } from "../utils/position";
import { PositionContext } from "../contexts/position";

function UserPosition() {
  const [userPosition, setUserPosition] = React.useState<GeolocationPosition | null>(null);

  const positionCtx = React.useContext(PositionContext);

  React.useEffect(() => {
    const posSub = positionCtx?.watchPosition().subscribe((position: GeolocationPosition | null) => {
      setUserPosition(position);
    });
    return () => {
      posSub?.unsubscribe();
    }
  }, [positionCtx]);

  if (!userPosition) {
    return null;
  }

  return (
    <Marker position={geolocationToLatLng(userPosition)} icon={{
      path: google.maps.SymbolPath.CIRCLE,
      fillColor: '#1771F1',
      fillOpacity: 1,
      scale: 10,
      strokeColor: 'white',
      strokeWeight: 2,
      rotation: 0,
    }} />
  );
}

export default UserPosition;
