import axios from "axios";

export const handleJoinList = async (name?: string, minRole?: string, authenticatedUserEmail?: string, successText?: string, errorText?: string) => {
  if (!name) {
    return;
  }

  try {
    await axios.post("https://hooks.slack.com/services/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",
      JSON.stringify({
        text: `User \`${authenticatedUserEmail}\` has joined the waiting list for \`${name}\` feature with minimum role of \`${minRole}\`.`,
        icon_emoji: ":tada:",
        username: "Feature Waiting List",
      }), {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    alert(successText);
  } catch (error) {
    console.error("Error sending notification to Slack:", error);
    alert(errorText);
  }
};
