import { Button, Dialog, DialogActions, DialogContent, DialogContentText, ListItemIcon, ListItemText, MenuItem, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../theme";
import { useTranslation } from "react-i18next";
import CardButton from "./CardButton";
import CookieIcon from '@mui/icons-material/Cookie';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import GavelIcon from '@mui/icons-material/Gavel';

export interface IubendaProps {
  menuItems?: boolean;
}

const Iubenda = (props: IubendaProps) => {
  const [ open, setOpen ] = React.useState(false);
  const [ link, setLink ] = React.useState("");
  const [ content, setContent ] = React.useState("");

  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setLink("");
    setContent("");
  };

  const prefix = 'https://';

  const loadPrivacyPolicy = () => {
    setLink(prefix+t("www.iubenda.com/privacy-policy/95367344"));
    fetch(prefix+t("www.iubenda.com/api/privacy-policy/95367344"))
      .then(response => response.json())
      .then(data => {
        setContent(data.content);
      });
  };

  const loadCookiePolicy = () => {
    setLink(prefix+t("www.iubenda.com/privacy-policy/95367344/cookie-policy"));
    fetch(prefix+t("www.iubenda.com/api/privacy-policy/95367344/cookie-policy"))
      .then(response => response.json())
      .then(data => {
        setContent(data.content);
      });
  };

  const loadTermsAndConditions = () => {
    setLink(prefix+t("www.iubenda.com/terms-and-conditions/95367344"));
    fetch(prefix+t("www.iubenda.com/api/terms-and-conditions/95367344"))
      .then(response => response.json())
      .then(data => {
        setContent(data.content);
      });
  };

  return (
    <React.Fragment>
      { !props.menuItems && <React.Fragment>
        <CardButton text={t("Cookie Policy")}
        icon={<CookieIcon />}
        onClick={() => {
          loadCookiePolicy();
          handleClickOpen();
        }} />
        <CardButton text={t("Privacy Policy")}
        icon={<PrivacyTipIcon />}
        onClick={() => {
          loadPrivacyPolicy();
          handleClickOpen();
        }} />
        <CardButton text={t("Terms and Conditions")}
        icon={<GavelIcon />}
        onClick={() => {
          loadTermsAndConditions();
          handleClickOpen();
        }} />
      </React.Fragment> }

      { props.menuItems && <React.Fragment>
        <MenuItem onClick={() => {
          loadCookiePolicy();
          handleClickOpen();
        }}>
          <ListItemIcon>
            <CookieIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Cookie Policy")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          loadPrivacyPolicy();
          handleClickOpen();
        }}>
          <ListItemIcon>
            <PrivacyTipIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Privacy Policy")}</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => {
          loadTermsAndConditions();
          handleClickOpen();
        }}>
          <ListItemIcon>
            <GavelIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t("Terms and Conditions")}</ListItemText>
        </MenuItem>
      </React.Fragment> }

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
      >
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: content }} />
        </DialogContent>
        <DialogActions>
          { link && <a href={link} target="_blank" rel="noreferrer"><Button>
            { t('Open') }
          </Button></a> }
          <Button onClick={handleClose} autoFocus>
            { t('Close') } 
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Iubenda;
