export const getMarksForPhase = (phase?: string): { value: number, label: string }[] | undefined => {
  switch (phase) {
    case 'Emergence':
      return [
        {
          value: 0.2,
          label: '',
        },
      ];
    case 'Rising':
      return [
        {
          value: 0.3,
          label: '',
        },
      ];
    case 'Flowering':
      return [
        {
          value: 0.7,
          label: '',
        },
      ];
    case 'Ripening':
      return [
        {
          value: 0.3,
          label: '',
        },
      ];
    default:
      return undefined;
  }
};
