import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

export interface ZonationProps {
  open: boolean;
  onClose: (index?: number) => void;
}

function Zonation(props: ZonationProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            const zones = parseInt(formJson.zones) ?? undefined;
            if (zones <= 0) {
              return;
            }
            props.onClose(zones);
          },
        }}
      >
        <DialogTitle>{t('Zonation')}</DialogTitle>
        <DialogContent>
          <TextField
            id="zones"
            name="zones"
            defaultValue={5}
            autoFocus
            margin="dense"
            label={t("Number of zones")}
            fullWidth
            type="number"
            variant="standard" />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
          <Button type="submit">{t('Compute')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Zonation;
