import React from "react";
import { useLocation } from "react-router-dom";

const useEmbed = () => {
  const [embed, setEmbed] = React.useState<boolean>(false);

  const location = useLocation();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const embed = urlParams.get("embed");
    setEmbed(embed === "true");

    if (window.parent !== window) {
      window.parent.postMessage({ type: "location", value: window.location.href }, "*");
    }
  }, [location]);

  const appendParams = React.useCallback((url: string) => {
    if (!embed) {
      return url;
    }

    const separator = url.includes("?") ? "&" : "?";
    return `${url}${separator}embed=true`;
  }, [embed]);

  return {
    embed,
    appendParams,
  };
};

export default useEmbed;
