import React from "react";
import MapFull from "./MapFull";
import { computeZoom } from "../utils/position";
import Loading from "./Loading";
import Polygon from "./Polygon";
import { secondary } from "../../theme";
import { useNavigate } from "react-router-dom";
import useEmbed from "../hooks/useEmbed";

export interface MapAllFieldsProps {
  fields: any[];
  pathPrefix: string;
}

const MapAllFields = (props: MapAllFieldsProps) => {
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ latLngsList, setLatLngsList ] = React.useState<google.maps.LatLngLiteral[][] | undefined>();

  const divRef = React.createRef<HTMLDivElement>();
  const [topOffset, setTopOffset] = React.useState<number | undefined>(0);

  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  React.useEffect(() => {
    const b = new google.maps.LatLngBounds();
    const tmpLatLngs: google.maps.LatLngLiteral[] = [];
    const latLngsList: google.maps.LatLngLiteral[][] = [];
    props.fields.forEach((field: any) => {
      const fieldLatLngs: google.maps.LatLngLiteral[] = [];
      field.lat_lngs.forEach((latLng: any) => {
        b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        tmpLatLngs.push({ lat: latLng.lat, lng: latLng.lng });
        fieldLatLngs.push({ lat: latLng.lat, lng: latLng.lng });
      });
      latLngsList.push(fieldLatLngs);
    });
    setDefaultCenter(b.getCenter().toJSON());
    setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
    setLatLngsList(latLngsList);
  }, [props.fields]);

  React.useEffect(() => {
    if (!divRef.current) {
      return;
    }
    const handleResize = () => {
      const topPosition = divRef.current?.getBoundingClientRect().top;
      if (topPosition) {
        setTopOffset(topPosition);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [divRef]);

  if (!defaultCenter) {
    return (
      <Loading open />
    );
  }

  return (
    <div ref={divRef} style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
    }}>
      { topOffset && <MapFull
      height={'calc(100% - ' + topOffset + 'px - 56px)'}
      defaultCenter={defaultCenter}
      defaultZoom={defaultZoom}>
        { latLngsList && latLngsList.map((latLngs, i) => (
          <Polygon key={i} path={latLngs}
          color={secondary}
          opacity={0.3}
          onClick={() => {
            navigate(appendParams(`${props.pathPrefix}/${props.fields[i].uuid}`));
          }} />
        ))}
      </MapFull> }
    </div>
  );
};

export default MapAllFields;
