import { Box, Divider, List } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import AnalysisRow from "../common/components/AnalysisRow";

export interface RecentMappingsProps {
  analysis: {
    uuid: string;
    created_at: string;
    state: string;
    agriculture_analysis_data: {
      orthophoto_png: string,
    },
    field: {
      name: string;
      uuid: string;
    }
  }[];
}

function RecentMappings(props: RecentMappingsProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box style={{
        margin: 20,
        marginBottom: 0,
      }}>
        <div style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: 20,
        }}>
          <span>{t('Recent Mappings')}</span>
        </div>
      </Box>
      <List sx={{ width: '100%' }}>
        {props.analysis.map((mapping, index) => <React.Fragment key={mapping.uuid}>
          <AnalysisRow analysis={mapping} prefix="/app/fields/" />
          { index < props.analysis.length - 1 && <Divider variant="inset" component="li" /> }
        </React.Fragment>)}
      </List>
    </React.Fragment>
  );
}

export default RecentMappings;
