import React from "react";
import { BehaviorSubject, Observable } from 'rxjs';

export class PositionManager {
  private positionSub = new BehaviorSubject<GeolocationPosition | null>(null);

  constructor() {
    navigator.geolocation.watchPosition((position: GeolocationPosition) => {
      this.positionSub.next(position);
    });
  }

  getPosition(): GeolocationPosition | null {
    return this.positionSub.getValue();
  }

  watchPosition(): Observable<GeolocationPosition | null> {
    return this.positionSub.asObservable();
  }
};

export const PositionContext = React.createContext<PositionManager | null>(null);

export const defaultPositionManager = new PositionManager();
