export function numberFormat(value: number | undefined | null, digits: number = 0): string {
  if (value === undefined || value === null) {
    return '';
  }
  return value.toFixed(digits);
}

export function dateFormat(date: Date | string | undefined | null,
  showTime?: boolean,
  options?: Intl.DateTimeFormatOptions): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }
  if (date === undefined || date === null) {
    return '';
  }
  if (showTime) {
    return date.toLocaleString(undefined, options);
  }
  return date.toLocaleDateString(undefined, options);
}

export function humanFileSize(bytes: number, si=true, dp=1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si 
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] 
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10**dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
}
