import { useMap } from "@vis.gl/react-google-maps";
import * as GeoTIFF from "geotiff";
import UTMLatLng from "utm-latlng";
import React from "react";
import Loading from "./Loading";

export interface GeoTIFFLayerProps extends React.PropsWithChildren<{}> {
  blob?: Blob;
  rgb?: boolean;
  height?: number | string;
  width?: number | string;
  top?: number | string;
  left?: number | string;
  range?: [number, number];
  onBoundsChange?: (bounds: google.maps.LatLngBounds) => void;
  setMin?: (min: number) => void;
  setMax?: (max: number) => void;
  reversed?: boolean;
  clipMax?: number;     // hard limit for the maximum value
  clipMin?: number;     // hard limit for the minimum value
  rangeMin?: number;    // minimum value for the range of colors
  absolute?: boolean;   // use absolute values
}

// all parameters are in the range [0, 1]
function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1/3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1/3);
  }

  return [r, g, b];
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}

function singleChannelToRGB(value: number, min: number, max: number, reversed: boolean = false): [number, number, number] {
  const percent = (value - min) / (max - min);
  // we use values from 0° to 120°, that is 1/3 of the color wheel (360°)
  const h = reversed ? (1 - percent) / 3 : percent / 3;
  return hslToRgb(h, 1, 0.5);
}

function bringToByte(value: number): number {
  if (value > 0 && value < 1) {
    return Math.ceil(value * 255);
  }
  return Math.ceil(value);
}

function GeoTIFFLayer(props: GeoTIFFLayerProps) {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [raster, setRaster] = React.useState<GeoTIFF.ReadRasterResult | null>(null);
  const [imageWidth, setImageWidth] = React.useState<number>(0);
  const [imageHeight, setImageHeight] = React.useState<number>(0);
  const [bounds, setBounds] = React.useState<google.maps.LatLngBounds | null>(null);
  const [dataURL, setDataURL] = React.useState<string | null>(null);
  const [groundOverlay, setGroundOverlay] = React.useState<google.maps.GroundOverlay | null>(null);

  const map = useMap();

  const isInRange = React.useCallback((value: number) => {
    if (!props.range) {
      return true;
    }

    return value >= props.range[0] && value <= props.range[1];
  } , [props.range]);

  React.useEffect(() => {
    const canvas = document.createElement('canvas');
    canvas.setAttribute('width', imageWidth.toString());
    canvas.setAttribute('height', imageHeight.toString());
    canvas.style.position = 'absolute';
    canvas.style.top = '0';
    canvas.style.left = '0';
    canvas.style.zIndex = '-1000';
    canvas.style.overflow = 'hidden';
    document.body.appendChild(canvas);

    setTimeout(() => {
      canvas.style.display = 'none';
      canvas.setAttribute('width', '0');
      canvas.setAttribute('height', '0');
      try {
        document.body.removeChild(canvas);
      } catch (e) {}
    });

    const { width, height } = canvas.getBoundingClientRect();

    const context = canvas.getContext('2d');
    if (!context) {
      return;
    }

    //const { devicePixelRatio:ratio=1 } = window;
    const ratio = 1;
    canvas.width = width*ratio;
    canvas.height = height*ratio;
    context.scale(ratio, ratio);

    if (!raster) {
      return;
    }

    const imageData = context.createImageData(imageWidth, imageHeight);
    const data = imageData.data;

    if (props.rgb) {
      let o = 0;
      for (let i = 0; i < raster.length; i += 4) {
        data[o] = bringToByte(raster[i] as number);
        data[o + 1] = bringToByte(raster[i + 1] as number);
        data[o + 2] = bringToByte(raster[i + 2] as number);
        data[o + 3] = bringToByte(raster[i + 3] as number);
        o += 4;
      }
    } else {
      let o = 0;
      const r0 = raster[0] as Float32Array;

      let min = 10000;
      let max = -10000;

      if (props.absolute) {
        min = props.rangeMin ?? props.clipMin ?? -1;
        max = props.clipMax ?? 1;
      } else {
        for (let i = 0; i < r0.length; i++) {
          // TODO
          if (r0[i] < min && (props.clipMin === undefined || r0[i] >= props.clipMin)) {
            min = r0[i];
          } else if (r0[i] > max && (props.clipMax === undefined || r0[i] <= props.clipMax)) {
            max = r0[i];
          }
        }
  
        if (props.clipMin !== undefined) {
          min = Math.max(min, props.clipMin);
        }
        if (props.clipMax !== undefined) {
          max = Math.min(max, props.clipMax);
        }
      }

      if (props.setMin) {
        props.setMin(min);
      }
      if (props.setMax) {
        props.setMax(max);
      }

      for (let i = 0; i < r0.length; i++) {
        if (Number.isNaN(r0[i])) {
          data[o] = 0;
          data[o + 1] = 0;
          data[o + 2] = 0;
          data[o + 3] = 0;
          o += 4;
          continue;
        }
        if (!isInRange(r0[i])) {
          data[o] = 0;
          data[o + 1] = 0;
          data[o + 2] = 0;
          data[o + 3] = 0;
          o += 4;
          continue;
        }
        const [r, g, b] = singleChannelToRGB(
          props.rangeMin !== undefined ? Math.max(r0[i], props.rangeMin) : r0[i],
          props.rangeMin !== undefined ? Math.max(min, props.rangeMin) : min,
          max,
          props.reversed
        );
        data[o] = Math.ceil(r * 255);
        data[o + 1] = Math.ceil(g * 255);
        data[o + 2] = Math.ceil(b * 255);
        // TODO
        data[o + 3] = Number.isNaN(r0[i]) || r0[i] < 0 ? 0 : 255;
        o += 4;
      }
    }

    context.putImageData(imageData, 0, 0);
    
    // convert to blob url
    canvas.toBlob((b) => {
      if (!b) {
        return;
      }

      const u = URL.createObjectURL(b);
      setDataURL(u);
    }, 'image/png', 1);
  }, [raster, imageWidth, imageHeight, props.range]);

  const renderBlob = React.useCallback(async () => {
    setLoading(true);
    if (!props.blob) {
      return;
    }

    const array = await props.blob.arrayBuffer();

    const gtiff = await GeoTIFF.fromArrayBuffer(array);
    const image = await gtiff.getImage();
    setImageWidth(image.getWidth());
    setImageHeight(image.getHeight());

    const geoKeys = image.getGeoKeys();
    let b: google.maps.LatLngBounds;
    if (geoKeys.GTCitationGeoKey) {
      const utmZoneSplit = (geoKeys.GTCitationGeoKey || geoKeys.GeogCitationGeoKey).split(' ');
      const utmZone = utmZoneSplit[utmZoneSplit.length - 1];
      const utmZoneNumber = parseInt(utmZone.substring(0, utmZone.length - 1));
      const utmZoneLetter = utmZone[utmZone.length - 1];

      const [gx1, gy1, gx2, gy2] = image.getBoundingBox();

      const utm = new UTMLatLng();
      b = new google.maps.LatLngBounds(
        new google.maps.LatLng(utm.convertUtmToLatLng(gx1, gy1, utmZoneNumber, utmZoneLetter) as google.maps.LatLngLiteral),
        new google.maps.LatLng(utm.convertUtmToLatLng(gx2, gy2, utmZoneNumber, utmZoneLetter) as google.maps.LatLngLiteral)
      );
    } else {
      const [gx1, gy1, gx2, gy2] = image.getBoundingBox();

      b = new google.maps.LatLngBounds(
        new google.maps.LatLng(gy1, gx1),
        new google.maps.LatLng(gy2, gx2)
      );
    }
    if (props.rgb) {
      const r = await image.readRGB({ enableAlpha: true });
      setRaster(r);
    } else {
      const r = await image.readRasters({ samples: [0] });
      setRaster(r);
    }
    setBounds(b);
  }, [props.blob]);

  React.useEffect(() => {
    renderBlob().then(() => {});
  }, [renderBlob]);

  React.useEffect(() => {
    if (props.onBoundsChange && bounds) {
      props.onBoundsChange(bounds);
    }
  }, [bounds]);

  React.useEffect(() => {
    if (!map || !bounds || !dataURL) {
      return;
    }

    if (groundOverlay) {
      groundOverlay.setMap(null);
    }

    const overlay = new google.maps.GroundOverlay(
      dataURL,
      bounds,
      {
        //opacity: props.rgb ? 1 : 0.7,
        opacity: 1,
        clickable: false,
      }
    );
    overlay.setMap(map);
    setGroundOverlay(overlay);
    setLoading(false);
  }, [map, bounds, dataURL]);

  React.useEffect(() => {
    return () => {
      if (groundOverlay) {
        groundOverlay.setMap(null);
      }
    };
  }, [groundOverlay]);

  React.useEffect(() => {
    return () => {
      if (dataURL) {
        URL.revokeObjectURL(dataURL);
      }
    }
  }, [dataURL]);

  if (loading) {
    return (
      <React.Fragment>
        <Loading open={loading}
          height={props.height}
          width={props.width}
          top={props.top}
          left={props.left} />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment></React.Fragment>
  );
}

export default GeoTIFFLayer;
