import Dexie, { Table } from 'dexie';

export interface FieldTableData {
  id: string;
  bounds: google.maps.LatLngBoundsLiteral;
}

export interface AnalysisTableData {
  id: string;
  bounds: google.maps.LatLngBoundsLiteral;
}

export class CacheDB extends Dexie {
  fields!: Table<FieldTableData, string>;
  analyses!: Table<AnalysisTableData, string>;

  constructor(name: string) {
    super(name);
    this.version(1).stores({
      fields: 'id',
      analyses: 'id',
    });
  }
}

export var db: CacheDB;

export function initDB(name: string) {
  db = new CacheDB(name);
}
