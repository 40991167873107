import React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from "@mui/material";

export interface RenameProps {
  title?: string;
  text?: string | React.ReactNode;
  confirmButtonText?: string;
  open: boolean;
  onClose: (name?: string) => void;
}

function Rename(props: RenameProps) {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={props.open}
        onClick={(event) => {
          event.stopPropagation();
        }}
        onClose={() => {props.onClose()}}
        PaperProps={{
          component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            event.stopPropagation();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            props.onClose(formJson.name);
          },
        }}
      >
        <DialogTitle>{ props.title ?? t('Rename') }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { props.text ?? t('Rename the field.')}
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="name"
            label={t('Name')}
            type="text"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
          <Button type="submit">{t(props.confirmButtonText ?? 'Rename')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default Rename;
