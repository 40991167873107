import { Card, CardActions, CardContent, IconButton, Typography } from "@mui/material";
import React from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useTranslation } from "react-i18next";
import TimeLineCard from "../common/components/TimeLineCard";
import { AreaPlot, ChartsAxis, ChartsOnAxisClickHandler, ChartsTooltip, MarkPlot, ResponsiveChartContainer } from "@mui/x-charts";
import { primary } from "../theme";

const randomNumber = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1) + min);

export interface TimeLineContentProps {
  showOrthoTimeline: boolean;
  analysis: {
    uuid: string;
    created_at: string;
    agriculture_analysis_data: {
      orthophoto_png: string;
      index_data: {
        name: string;
        png: string;
        tiff: string;
        average: number;
        min: number;
        max: number;
        std_dev: number;
      }[];
    }[];
  }[];
  onAnalysisSelected?: (analysisUuid: string) => void;
  currentIndex?: string;
}

export const TimeLineContent = (props: TimeLineContentProps) => {
  return <div style={{
    display: 'flex',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    paddingLeft: 20,
    paddingRight: 20,
    height: 150,
  }}>
    {props.showOrthoTimeline && props.analysis.map((ana) => <React.Fragment key={ana.uuid}>
      <TimeLineCard analysis={ana} onClick={() => {
        if (props.onAnalysisSelected) {
          props.onAnalysisSelected(ana.uuid);
        }
      }}/>
    </React.Fragment>)}
    {!props.showOrthoTimeline && <ResponsiveChartContainer
      sx={{
        cursor: 'pointer',
        width: '100%',
      }}
      series={[{
        type: 'line',
        dataKey: 'avg',
        valueFormatter: (value) => value?.toFixed(2) ?? 'N/A',
        color: primary,
        area: true,
      }]}
      dataset={props.analysis.map((ana, i) => ({
        index: i,
        created_at: new Date(ana.created_at),
        uuid: ana.uuid,
        avg: ana.agriculture_analysis_data[0].index_data.find((d) => d.name === props.currentIndex)?.average,
      }))}
      xAxis={[
        {
          dataKey: 'created_at',
          scaleType: 'band',
          valueFormatter: (value) => new Date(value).toLocaleDateString(),
        },
      ]}
      >
      <ChartsAxis
        leftAxis={null}
      />
      <ChartsOnAxisClickHandler onAxisClick={(_, data: any) => {
        if (props.onAnalysisSelected) {
          const ann = props.analysis[data.dataIndex];
          props.onAnalysisSelected(ann.uuid);
        }
      }} />
      <AreaPlot />
      <MarkPlot />
      <ChartsTooltip />
    </ResponsiveChartContainer>}
  </div>
};

export interface TimeLineProps {
  analysis: {
    uuid: string;
    created_at: string;
    agriculture_analysis_data: {
      orthophoto_png: string,
      index_data: {
        name: string,
        png: string,
        tiff: string,
        average: number,
        min: number,
        max: number,
        std_dev: number,
      }[],
    }[],
  }[];
  currentAnalysis?: {
    uuid: string;
    created_at: string;
  };
  currentIndex?: string;
  onAnalysisSelected?: (analysisUuid: string) => void;
  contained?: boolean;
}

function TimeLine(props: TimeLineProps) {
  const [ collapsed, setCollapsed ] = React.useState<boolean>(true);
  const [ analysis, setAnalysis ] = React.useState(props.analysis);

  const { t } = useTranslation();

  const style: React.CSSProperties = props.contained ? {
    width: 'calc((100% - 8px) / 2)',
    backgroundColor: collapsed ? "white" : primary,
    color: collapsed ? "black" : "white",
  } : {
    position: 'absolute',
    bottom: 24,
    left: 8,
    right: 8,
    height: collapsed ? 56 : 216,
  };

  const showOrthoTimeline = !props.currentIndex || props.currentIndex === 'Orthophoto' || props.currentIndex === 'None';

  React.useEffect(() => {
    if (showOrthoTimeline) {
      setAnalysis(props.analysis);
    } else {
      setAnalysis([...props.analysis].reverse());
    }
  }, [props.analysis, showOrthoTimeline]);

  return (
    <Card style={style}>
      { !collapsed && props.contained && <React.Fragment>
        <Card style={{
          position: 'fixed',
          bottom: 90,
          left: 8,
          right: 8,
          height: 200,
          backgroundColor: "white",
        }}>
          <CardContent style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 0,
            paddingRight: 0,
          }}>
            <TimeLineContent
              showOrthoTimeline={showOrthoTimeline}
              analysis={analysis}
              onAnalysisSelected={props.onAnalysisSelected}
              currentIndex={props.currentIndex}
            />
          </CardContent>
        </Card>
      </React.Fragment> }
      { !collapsed && !props.contained &&
      <CardContent style={{
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 0,
        paddingRight: 0,
      }}>
        <TimeLineContent
          showOrthoTimeline={showOrthoTimeline}
          analysis={analysis}
          onAnalysisSelected={props.onAnalysisSelected}
          currentIndex={props.currentIndex}
        />
      </CardContent>
      }
      <CardActions disableSpacing onClick={() => setCollapsed(!collapsed)}>
        <Typography variant="body1" style={{
          marginLeft: props.contained ? 5 : 20,
        }}>
          { props.currentAnalysis
          ? (!props.contained ? t('Monitoring date') + ': ' : '') + new Date(props.currentAnalysis.created_at).toLocaleDateString()
          : t('No analysis selected') }
        </Typography>
        <IconButton
          style={{
            marginLeft: 'auto',
            color: props.contained ? (collapsed ? "rgba(0,0,0,0.54)" : "white") : undefined,
          }}
        >
          { collapsed && <ExpandMoreIcon /> }
          { !collapsed && <ExpandLessIcon /> }
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default TimeLine;
