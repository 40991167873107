import React from "react";
import useApi from "./useApi";

export interface MultiSizeImage {
  smallSize: string;
  mediumSize: string;
  fullSize: string;
  thumbnail: string;
}

export interface PresignedAnalysis {
  orthophoto: string;
  orthophoto_png: string;
  index_data: {
    name: string;
    png: string;
    tiff: string;
    shapefilePng?: string;
    shapefiles?: [string];
    tiffMultiSize?: MultiSizeImage;
  }[];
  orthophotoMultiSize?: MultiSizeImage;
  orthophotoMsMultiSize?: MultiSizeImage;
}

export function getIndex(analysis?: PresignedAnalysis, name?: string) {
  if (!analysis || !name || !analysis.index_data) {
    return undefined;
  }
  return analysis.index_data.find((idx) => idx.name.toLowerCase() === name.toLowerCase());
}

export type IMAGE_SIZE = string;
export const IMAGE_SIZE_SMALL: IMAGE_SIZE = 'small';
export const IMAGE_SIZE_MEDIUM: IMAGE_SIZE = 'medium';
export const IMAGE_SIZE_FULL: IMAGE_SIZE = 'full';
export const IMAGE_SIZE_THUMBNAIL: IMAGE_SIZE = 'thumbnail';

export type IMAGE_KEY = string;
export const IMAGE_ORTHOPHOTO: IMAGE_KEY = 'Orthophoto';
export const IMAGE_ORTHOPHOTO_PNG: IMAGE_KEY = 'Orthophoto_PNG';

export const IMAGE_VARI_TIFF: IMAGE_KEY = 'VARI_TIFF';
export const IMAGE_VARI_VINEYARD_TIFF: IMAGE_KEY = 'VARI_VINEYARD_TIFF';
export const IMAGE_GRVI_TIFF: IMAGE_KEY = 'GRVI_TIFF';
export const IMAGE_GRVI_VINEYARD_TIFF: IMAGE_KEY = 'GRVI_VINEYARD_TIFF';
export const IMAGE_NDVI_TIFF: IMAGE_KEY = 'NDVI_TIFF';
export const IMAGE_NDVI_VINEYARD_TIFF: IMAGE_KEY = 'NDVI_VINEYARD_TIFF';
export const IMAGE_GNDVI_TIFF: IMAGE_KEY = 'GNDVI_TIFF';
export const IMAGE_GNDVI_VINEYARD_TIFF: IMAGE_KEY = 'GNDVI_VINEYARD_TIFF';
export const IMAGE_OSAVI_TIFF: IMAGE_KEY = 'OSAVI_TIFF';
export const IMAGE_OSAVI_VINEYARD_TIFF: IMAGE_KEY = 'OSAVI_VINEYARD_TIFF';
export const IMAGE_CVI_TIFF: IMAGE_KEY = 'CVI_TIFF';
export const IMAGE_CVI_VINEYARD_TIFF: IMAGE_KEY = 'CVI_VINEYARD_TIFF';
export const IMAGE_NDWI_TIFF: IMAGE_KEY = 'NDWI_TIFF';
export const IMAGE_NDWI_VINEYARD_TIFF: IMAGE_KEY = 'NDWI_VINEYARD_TIFF';
export const IMAGE_NDREI_TIFF: IMAGE_KEY = 'NDREI_TIFF';
export const IMAGE_NDREI_VINEYARD_TIFF: IMAGE_KEY = 'NDREI_VINEYARD_TIFF';
export const IMAGE_LCI_TIFF: IMAGE_KEY = 'LCI_TIFF';
export const IMAGE_LCI_VINEYARD_TIFF: IMAGE_KEY = 'LCI_VINEYARD_TIFF';

export const IMAGE_VARI_PNG: IMAGE_KEY = 'VARI_PNG';
export const IMAGE_GRVI_PNG: IMAGE_KEY = 'GRVI_PNG';
export const IMAGE_NDVI_PNG: IMAGE_KEY = 'NDVI_PNG';
export const IMAGE_GNDVI_PNG: IMAGE_KEY = 'GNDVI_PNG';
export const IMAGE_OSAVI_PNG: IMAGE_KEY = 'OSAVI_PNG';
export const IMAGE_CVI_PNG: IMAGE_KEY = 'CVI_PNG';
export const IMAGE_NDWI_PNG: IMAGE_KEY = 'NDWI_PNG';
export const IMAGE_NDREI_PNG: IMAGE_KEY = 'NDREI_PNG';
export const IMAGE_LCI_PNG: IMAGE_KEY = 'LCI_PNG';

export const IMAGE_SHAPEFILE_VARI: IMAGE_KEY = 'Shapefile_VARI';
export const IMAGE_SHAPEFILE_GRVI: IMAGE_KEY = 'Shapefile_GRVI';
export const IMAGE_SHAPEFILE_NDVI: IMAGE_KEY = 'Shapefile_NDVI';
export const IMAGE_SHAPEFILE_GNDVI: IMAGE_KEY = 'Shapefile_GNDVI';
export const IMAGE_SHAPEFILE_OSAVI: IMAGE_KEY = 'Shapefile_OSAVI';
export const IMAGE_SHAPEFILE_CVI: IMAGE_KEY = 'Shapefile_CVI';
export const IMAGE_SHAPEFILE_NDWI: IMAGE_KEY = 'Shapefile_NDWI';
export const IMAGE_SHAPEFILE_NDREI: IMAGE_KEY = 'Shapefile_NDREI';
export const IMAGE_SHAPEFILE_LCI: IMAGE_KEY = 'Shapefile_LCI';

export const IMAGE_DEM_TIFF: IMAGE_KEY = 'DEM_TIFF';
export const IMAGE_DSM_TIFF: IMAGE_KEY = 'DSM_TIFF';
export const IMAGE_DTM_TIFF: IMAGE_KEY = 'DTM_TIFF';

export const IMAGE_DEM_PNG: IMAGE_KEY = 'DEM_PNG';
export const IMAGE_DSM_PNG: IMAGE_KEY = 'DSM_PNG';
export const IMAGE_DTM_PNG: IMAGE_KEY = 'DTM_PNG';

export const MTL_FILE: IMAGE_KEY = 'MTL';
export const OBJ_FILE: IMAGE_KEY = 'OBJ';
export const GLB_FILE: IMAGE_KEY = 'GLB';

export const CONTOUR_LINES_DXF: IMAGE_KEY = 'Contour_Lines_DXF';
export const CONTOUR_LINES_SHP: IMAGE_KEY = 'Contour_Lines_SHP';

export function getShapefileKey(index: string | null | undefined): IMAGE_KEY | null {
  if (!index) {
    return null;
  }

  switch (index.toUpperCase()) {
    case 'VARI':
      return IMAGE_SHAPEFILE_VARI;
    case 'grvi':
      return IMAGE_SHAPEFILE_GRVI;
    case 'NDVI':
      return IMAGE_SHAPEFILE_NDVI;
    case 'GNDVI':
      return IMAGE_SHAPEFILE_GNDVI;
    case 'OSAVI':
      return IMAGE_SHAPEFILE_OSAVI;
    case 'CVI':
      return IMAGE_SHAPEFILE_CVI;
    case 'NDWI':
      return IMAGE_SHAPEFILE_NDWI;
    case 'NDREI':
      return IMAGE_SHAPEFILE_NDREI;
    case 'LCI':
      return IMAGE_SHAPEFILE_LCI;
    default:
      return null;
  }
}

function getMultiSizeImage(
  multiSizeImage?: MultiSizeImage,
  size?: IMAGE_SIZE
): string {
  if (!multiSizeImage) {
    return '';
  }
  switch (size) {
    case IMAGE_SIZE_SMALL:
      return multiSizeImage.smallSize;
    case IMAGE_SIZE_MEDIUM:
      return multiSizeImage.mediumSize;
    case IMAGE_SIZE_FULL:
      return multiSizeImage.fullSize;
    case IMAGE_SIZE_THUMBNAIL:
      return multiSizeImage.thumbnail;
    default:
      return multiSizeImage.fullSize;
  }
}

function imageToUrl(
  presigned: PresignedAnalysis,
  image: IMAGE_KEY,
  size: IMAGE_SIZE
): string | undefined {
  let url = '';
  switch (image) {
    case IMAGE_ORTHOPHOTO:
      url = getMultiSizeImage(presigned.orthophotoMultiSize, size);
      if (url === '') {
        if (size === IMAGE_SIZE_THUMBNAIL) {
          return presigned.orthophoto_png;
        }
        return presigned.orthophoto;
      }
      return url;
    case IMAGE_ORTHOPHOTO_PNG:
      return presigned.orthophoto_png;

    case IMAGE_VARI_TIFF:
      const vari = getIndex(presigned, 'VARI');
      if (!vari) {
        return undefined;
      }
      url = getMultiSizeImage(vari.tiffMultiSize, size);
      if (url === '') {
        return vari.tiff;
      }
      return url;
    case IMAGE_VARI_VINEYARD_TIFF:
      const variVineyard = getIndex(presigned, 'VARI - Vineyard');
      if (!variVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(variVineyard.tiffMultiSize, size);
      if (url === '') {
        return variVineyard.tiff;
      }
      return url;
    case IMAGE_GRVI_TIFF:
      const grvi = getIndex(presigned, 'grvi');
      if (!grvi) {
        return undefined;
      }
      url = getMultiSizeImage(grvi.tiffMultiSize, size);
      if (url === '') {
        return grvi.tiff;
      }
      return url;
    case IMAGE_GRVI_VINEYARD_TIFF:
      const grviVineyard = getIndex(presigned, 'grvi - Vineyard');
      if (!grviVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(grviVineyard.tiffMultiSize, size);
      if (url === '') {
        return grviVineyard.tiff;
      }
      return url;
    case IMAGE_NDVI_TIFF:
      const ndvi = getIndex(presigned, 'NDVI');
      if (!ndvi) {
        return undefined;
      }
      url = getMultiSizeImage(ndvi.tiffMultiSize, size);
      if (url === '') {
        return ndvi.tiff;
      }
      return url;
    case IMAGE_NDVI_VINEYARD_TIFF:
      const ndviVineyard = getIndex(presigned, 'NDVI - Vineyard');
      if (!ndviVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(ndviVineyard.tiffMultiSize, size);
      if (url === '') {
        return ndviVineyard.tiff;
      }
      return url;
    case IMAGE_GNDVI_TIFF:
      const gndvi = getIndex(presigned, 'GNDVI');
      if (!gndvi) {
        return undefined;
      }
      url = getMultiSizeImage(gndvi.tiffMultiSize, size);
      if (url === '') {
        return gndvi.tiff;
      }
      return url;
    case IMAGE_GNDVI_VINEYARD_TIFF:
      const gndviVineyard = getIndex(presigned, 'GNDVI - Vineyard');
      if (!gndviVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(gndviVineyard.tiffMultiSize, size);
      if (url === '') {
        return gndviVineyard.tiff;
      }
      return url;
    case IMAGE_OSAVI_TIFF:
      const osavi = getIndex(presigned, 'OSAVI');
      if (!osavi) {
        return undefined;
      }
      url = getMultiSizeImage(osavi.tiffMultiSize, size);
      if (url === '') {
        return osavi.tiff;
      }
      return url;
    case IMAGE_OSAVI_VINEYARD_TIFF:
      const osaviVineyard = getIndex(presigned, 'OSAVI - Vineyard');
      if (!osaviVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(osaviVineyard.tiffMultiSize, size);
      if (url === '') {
        return osaviVineyard.tiff;
      }
      return url;
    case IMAGE_CVI_TIFF:
      const cvi = getIndex(presigned, 'CVI');
      if (!cvi) {
        return undefined;
      }
      url = getMultiSizeImage(cvi.tiffMultiSize, size);
      if (url === '') {
        return cvi.tiff;
      }
      return url;
    case IMAGE_CVI_VINEYARD_TIFF:
      const cviVineyard = getIndex(presigned, 'CVI - Vineyard');
      if (!cviVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(cviVineyard.tiffMultiSize, size);
      if (url === '') {
        return cviVineyard.tiff;
      }
      return url;
    case IMAGE_NDWI_TIFF:
      const ndwi = getIndex(presigned, 'NDWI');
      if (!ndwi) {
        return undefined;
      }
      url = getMultiSizeImage(ndwi.tiffMultiSize, size);
      if (url === '') {
        return ndwi.tiff;
      }
      return url;
    case IMAGE_NDWI_VINEYARD_TIFF:
      const ndwiVineyard = getIndex(presigned, 'NDWI - Vineyard');
      if (!ndwiVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(ndwiVineyard.tiffMultiSize, size);
      if (url === '') {
        return ndwiVineyard.tiff;
      }
      return url;
    case IMAGE_NDREI_TIFF:
      const ndrei = getIndex(presigned, 'NDREI');
      if (!ndrei) {
        return undefined;
      }
      url = getMultiSizeImage(ndrei.tiffMultiSize, size);
      if (url === '') {
        return ndrei.tiff;
      }
      return url;
    case IMAGE_NDREI_VINEYARD_TIFF:
      const ndreiVineyard = getIndex(presigned, 'NDREI - Vineyard');
      if (!ndreiVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(ndreiVineyard.tiffMultiSize, size);
      if (url === '') {
        return ndreiVineyard.tiff;
      }
      return url;
    case IMAGE_LCI_TIFF:
      const lci = getIndex(presigned, 'LCI');
      if (!lci) {
        return undefined;
      }
      url = getMultiSizeImage(lci.tiffMultiSize, size);
      if (url === '') {
        return lci.tiff;
      }
      return url;
    case IMAGE_LCI_VINEYARD_TIFF:
      const lciVineyard = getIndex(presigned, 'LCI - Vineyard');
      if (!lciVineyard) {
        return undefined;
      }
      url = getMultiSizeImage(lciVineyard.tiffMultiSize, size);
      if (url === '') {
        return lciVineyard.tiff;
      }
      return url;

    case IMAGE_VARI_PNG:
      return getIndex(presigned, 'VARI')?.png;
    case IMAGE_GRVI_PNG:
      return getIndex(presigned, 'grvi')?.png;
    case IMAGE_NDVI_PNG:
      return getIndex(presigned, 'NDVI')?.png;
    case IMAGE_GNDVI_PNG:
      return getIndex(presigned, 'GNDVI')?.png;
    case IMAGE_OSAVI_PNG:
      return getIndex(presigned, 'OSAVI')?.png;
    case IMAGE_CVI_PNG:
      return getIndex(presigned, 'CVI')?.png;
    case IMAGE_NDWI_PNG:
      return getIndex(presigned, 'NDWI')?.png;
    case IMAGE_NDREI_PNG:
      return getIndex(presigned, 'NDREI')?.png;
    case IMAGE_LCI_PNG:
      return getIndex(presigned, 'LCI')?.png;

    case IMAGE_SHAPEFILE_VARI:
      return getIndex(presigned, 'VARI')?.shapefilePng;
    case IMAGE_SHAPEFILE_GRVI:
      return getIndex(presigned, 'grvi')?.shapefilePng;
    case IMAGE_SHAPEFILE_NDVI:
      return getIndex(presigned, 'NDVI')?.shapefilePng;
    case IMAGE_SHAPEFILE_GNDVI:
      return getIndex(presigned, 'GNDVI')?.shapefilePng;
    case IMAGE_SHAPEFILE_OSAVI:
      return getIndex(presigned, 'OSAVI')?.shapefilePng;
    case IMAGE_SHAPEFILE_CVI:
      return getIndex(presigned, 'CVI')?.shapefilePng;
    case IMAGE_SHAPEFILE_NDWI:
      return getIndex(presigned, 'NDWI')?.shapefilePng;
    case IMAGE_SHAPEFILE_NDREI:
      return getIndex(presigned, 'NDREI')?.shapefilePng;

    case IMAGE_DEM_TIFF:
      const dem = getIndex(presigned, 'dem');
      if (!dem) {
        return undefined;
      }
      url = getMultiSizeImage(dem.tiffMultiSize, size);
      if (url === '') {
        return dem.tiff;
      }
      return url;
    case IMAGE_DSM_TIFF:
      const dsm = getIndex(presigned, 'dsm');
      if (!dsm) {
        return undefined;
      }
      url = getMultiSizeImage(dsm.tiffMultiSize, size);
      if (url === '') {
        return dsm.tiff;
      }
      return url;
    case IMAGE_DTM_TIFF:
      const dtm = getIndex(presigned, 'dtm');
      if (!dtm) {
        return undefined;
      }
      url = getMultiSizeImage(dtm.tiffMultiSize, size);
      if (url === '') {
        return dtm.tiff;
      }
      return url;

    case IMAGE_DEM_PNG:
      return getIndex(presigned, 'dem')?.png;
    case IMAGE_DSM_PNG:
      return getIndex(presigned, 'dsm')?.png;
    case IMAGE_DTM_PNG:
      return getIndex(presigned, 'dtm')?.png;

    case MTL_FILE:
      return getIndex(presigned, 'mtl')?.tiff;
    case OBJ_FILE:
      return getIndex(presigned, 'obj')?.tiff;
    case GLB_FILE:
      return getIndex(presigned, 'glb')?.tiff;

    case CONTOUR_LINES_DXF:
      return getIndex(presigned, 'contour-lines-dxf')?.tiff;
    case CONTOUR_LINES_SHP:
      return getIndex(presigned, 'contour-lines-shp')?.tiff;

    default:
      return undefined;
  }
}

function useCache() {
  const [ cache, setCache ] = React.useState<Cache | null>(null);
  const [ cacheInitialized, setCacheInitialized ] = React.useState<boolean>(false);
  const [ progress, setProgress ] = React.useState<number>(0);

  const api = useApi();

  React.useEffect(() => {
    caches.open('analysis-cache').then((cache) => {
      // Cache opened successfully
      setCache(cache);
      setCacheInitialized(true);
    }).catch((error) => {
      // Failed to open cache
      console.error('cache failed to open', error);
      setCache(null);
      setCacheInitialized(true);
    });
  }, []);

  const getPresignedUrl = React.useCallback(async (
    analysisID: string,
    image: IMAGE_KEY,
    size: IMAGE_SIZE = IMAGE_SIZE_FULL
  ): Promise<string> => {
    const resp: any = await api.get(`/analysis/${analysisID}/presignedurl`);
    let presigned: PresignedAnalysis = {
      orthophoto: resp.orthophoto,
      orthophoto_png: resp.orthophotoPng,
      index_data: [],
      orthophotoMultiSize: resp.orthophotoMultiSize,
      orthophotoMsMultiSize: resp.orthophotoMsMultiSize,
    };
    for (const k in resp.indexesMap) {
      if (resp.indexesMap.hasOwnProperty(k)) {
        const idx = resp.indexesMap[k];
        presigned.index_data.push({
          name: k,
          png: idx.png,
          tiff: idx.tiff,
          shapefilePng: idx.shapefilePng,
          shapefiles: idx.shapefiles,
          tiffMultiSize: idx.tiffMultiSize,
        });
      }
    }

    const url = imageToUrl(presigned, image, size);
    if (!url) {
      throw new Error('invalid image ' + image);
    }

    return url;
  }, [api, api.get]);

  const downloadPresigned = React.useCallback(async (
    analysisID: string,
    image: IMAGE_KEY,
    size: IMAGE_SIZE = IMAGE_SIZE_FULL
  ): Promise<Blob> => {
    return new Promise<Blob>(async(resolve, reject) => {
      const key = `/${analysisID}/${image}/${size}`;
      // if we find in cache, return it
      if (cache && key) {
        const response = await cache.match(key);
        if (response) {
          resolve(response.blob());
          return;
        }
      }

      let url;
      try {
        url = await getPresignedUrl(analysisID, image, size);
      } catch (error) {
        console.error('failed to get presigned url', error);
        reject(error);
        return;
      }

      const worker = new Worker(new URL('../workers/cache.worker.ts', import.meta.url));
      worker.onmessage = async (e) => {
        if (e.data.type === 'progress') {
          setProgress(e.data.progress);
        } else if (e.data.type === 'error') {
          console.error(e.data.error);
          setProgress(0);
          worker.terminate();
          reject(new Error(e.data.error));
        } else if (e.data.type === 'success') {
          setProgress(0);
          worker.terminate();

          const blob = new Blob([e.data.blob]);
          if (cache && key) {
            await cache.put(key, new Response(blob));
          }
          resolve(e.data.blob);
        }
      };
      worker.postMessage({ url });
    });
  }, [api]);

  return { cacheInitialized, downloadPresigned, progress, getPresignedUrl };
}

export default useCache;
