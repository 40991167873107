export function whereOrganization(organizationId: string | null | undefined) {
  if (organizationId) {
    return '{organization: {uuid: {_eq: "' + organizationId + '"}}}';
  }
  return '{organization_id: {_is_null: true}}';
}

export function whereNotDeleted() {
  return '{deleted_at: {_is_null: true}}';
}

export function and(...args: string[]) {
  return '{_and: [' + args.join(', ') + ']}';
}
