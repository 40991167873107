import React from "react";
import { Map, MapCameraChangedEvent, MapMouseEvent } from '@vis.gl/react-google-maps';

export interface MapFullProps extends React.PropsWithChildren<{}> {
  onCameraChanged?: (ev: MapCameraChangedEvent) => void;
  onClick?: (e: MapMouseEvent) => void;
  defaultCenter?: google.maps.LatLngLiteral;
  center?: google.maps.LatLngLiteral;
  defaultZoom?: number;
  zoom?: number;
  height?: number | string;
}

function MapFull(props: MapFullProps) {
  return (
    <Map
      defaultZoom={ props.defaultZoom ?? 17 }
      zoom={props.zoom}
      mapTypeId={'hybrid'}
      defaultCenter={ props.defaultCenter ?? {
        lat: 45.1948487,
        lng: 7.8785499,
      }}
      center={props.center}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      style={{
        width: '100%',
        height: props.height ?? '100%',
      }}
      onClick={(e) => {
        if (props.onClick) {
          props.onClick(e);
        }
      }}
      onCameraChanged={(e) => {
        if (props.onCameraChanged) {
          props.onCameraChanged(e);
        }
      }}
    >
      { props.children }
    </Map>
  );
}

export default MapFull;
