import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import MapIcon from '@mui/icons-material/Map';
import ListIcon from '@mui/icons-material/List';
import { useTranslation } from "react-i18next";

export type TabType = string;

export const ListTab: TabType = 'LIST';
export const MapTab: TabType = 'MAP';

export interface FieldsTabsProps {
  onSelectedTab?: (tab: TabType) => void;
}

const FieldsTabs = (props: FieldsTabsProps) => {
  const [value, setValue] = React.useState(localStorage.getItem('fieldsTabLastStyle') === ListTab ? 1 : 0);

  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    localStorage.setItem('fieldsTabLastStyle', value === 0 ? MapTab : ListTab);

    switch (value) {
      case 0:
        if (props.onSelectedTab) {
          props.onSelectedTab(MapTab);
        }
        break;
      case 1:
        if (props.onSelectedTab) {
          props.onSelectedTab(ListTab);
        }
        break;
    }
  }, [value]);

  return (
    <Tabs
      value={value}
      onChange={handleChange}
      centered
    >
      <Tab icon={<MapIcon />} iconPosition="start" label={t('Map')} />
      <Tab icon={<ListIcon />} iconPosition="start" label={t('List')} />
    </Tabs>
  );
}

export default FieldsTabs;
