import React from "react";
import useUser from "../hooks/useUser";
import AcquireFeature from "../pages/AcquireFeature";

export interface RoleGuardProps extends React.PropsWithChildren<{}> {
  requiredRoles?: string[];
  featureName?: string;
}

const RoleGuard = (props: RoleGuardProps) => {
  const { hasRole } = useUser();

  if (props.requiredRoles && props.requiredRoles.length >= 0) {
    for (const role of props.requiredRoles) {
      if (!hasRole(role)) {
        return (
          <AcquireFeature name={props.featureName} minRole={role} />
        );
      }
    }
  }

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export default RoleGuard;
