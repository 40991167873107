import { DocumentNode, gql, useQuery } from "@apollo/client";
import React from "react";
import { OrganizationContext } from "../contexts/organization";
import { useAuth } from "react-oidc-context";

function useUser() {
  const [userID, setUserID] = React.useState<number | undefined>();
  const [organizationID, setOrganizationID] = React.useState<number | undefined>();
  const [organizationUUID, setOrganizationUUID] = React.useState<string | undefined>();
  const [authenticatedUserEmail, setAuthenticatedUserEmail] = React.useState<string | undefined>();
  const [roles, setRoles] = React.useState<string[] | undefined>();

  const organizationCtx = React.useContext(OrganizationContext);

  const { user } = useAuth();

  let query: DocumentNode;
  if (organizationCtx && organizationCtx.organizationID) {
    query = gql(`
      query GetUser {
        users {
          id
          email
        }
        organizations(where: {uuid: {_eq: "${organizationCtx.organizationID}"}}) {
          id
        }
      }`);
  } else {
    query = gql(`
      query GetUser {
        users {
          id
          email
        }
      }`);
  }

  const { loading, error, data } = useQuery(query);

  React.useEffect(() => {
    if (data && authenticatedUserEmail) {
      setUserID(data.users.find((user: any) => user.email === authenticatedUserEmail).id);
      if (data.organizations) {
        setOrganizationID(data.organizations[0].id);
      } else {
        setOrganizationID(undefined);
      }
    }
  }, [data, authenticatedUserEmail]);

  React.useEffect(() => {
    if (organizationCtx && organizationCtx.organizationID) {
      setOrganizationUUID(organizationCtx.organizationID);
    } else {
      setOrganizationUUID(undefined);
    }
  }, [organizationCtx]);

  React.useEffect(() => {
    setAuthenticatedUserEmail(user?.profile.email);
    setRoles((user?.profile?.roles as []) ?? []);
  }, [user]);

  const hasRole = React.useCallback((role?: string) => {
    if (!role) {
      return true;
    }

    return roles?.includes(role);
  }, [roles]);

  return { authenticatedUserEmail, userID, organizationID, organizationUUID, hasRole };
}

export default useUser;
