import { useMap } from "@vis.gl/react-google-maps";
import React from "react";
import { gray } from "../../theme";

export interface PolygonProps {
  path: google.maps.LatLngLiteral[];
  zIndex?: number;
  transparent?: boolean;
  opacity?: number;
  color?: string;
  onClick?: (e: google.maps.Polygon) => void;
}

function Polygon(props: PolygonProps) {
  const [ path, setPath ] = React.useState<google.maps.LatLngLiteral[]>([]);

  const map = useMap();

  React.useEffect(() => {
    let p = props.path;
    if (p.length === 0) {
      setPath([]);
      return;
    }

    if (p[0].lat !== p[p.length - 1].lat || p[0].lng !== p[p.length - 1].lng) {
      setPath([...p, p[0]]);
    } else {
      setPath(props.path);
    }
  }, [props.path]);

  React.useEffect(() => {
    const polygon = new google.maps.Polygon({
      path: path,
      geodesic: true,
      fillColor: props.color ?? gray,
      fillOpacity: props.transparent ? 0.0 : props.opacity ?? 1,
      strokeColor: props.color ?? gray,
      strokeOpacity: props.transparent ? 0.0 : 1,
      zIndex: props.zIndex,
      clickable: !!props.onClick,
    } as google.maps.PolygonOptions);
    polygon.setMap(map);
    polygon.addListener('click', () => {
      if (props.onClick) {
        props.onClick(polygon);
      }
    });

    return () => {
      polygon.setMap(null);
      polygon.unbindAll();
    };
  }, [map, path]);

  return null;
}

export default Polygon;
