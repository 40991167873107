import React from "react";
import MapFull from "../common/components/MapFull";
import UserPosition from "../common/components/UserPosition";
import BackButton from "../common/components/BackButton";
import { Fab } from "@mui/material";
import ShareIcon from '@mui/icons-material/Share';
import ShapeFileDialog from "../dialogs/ShapeFile";
import AnalysisLayer from "../common/components/AnalysisLayer";
import { IMAGE_SHAPEFILE_VARI, getShapefileKey } from "../common/hooks/useCache";
import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { and, whereNotDeleted } from "../common/utils/graphql";
import { computeZoom } from "../common/utils/position";
import { db } from "../common/db/db";
import Loading from "../common/components/Loading";

function Zonation() {
  const [ shapeFileDialogOpen, setShapeFileDialogOpen ] = React.useState<boolean>(false);
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();
  const [ currentAnalysis, setCurrentAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
  } | null>(null);

  const { fieldId, index } = useParams();

  const image = getShapefileKey(index) ?? IMAGE_SHAPEFILE_VARI;

  const { loading, error, data } = useQuery(gql(`
    query FieldDetails($fieldId: uuid!) {
      fields(where: {uuid: {_eq: $fieldId}}) {
        uuid
        name
        analyses(where: `+and(whereNotDeleted() + '{type: {_eq: "agriculture"}}')+`, order_by: {created_at: desc}) {
          uuid
          created_at
          agriculture_analysis_data {
            orthophoto
            orthophoto_png
            index_data(order_by: {name: asc}) {
              name
              png
              tiff
            }
          }
        }
        lat_lngs(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          lat
          lng
        }
      }
    }
  `), {
    variables: {
      fieldId,
    }
  });

  React.useEffect(() => {
    if (data) {
      setCurrentAnalysis(data.fields[0].analyses[0]);

      const b = new google.maps.LatLngBounds();
      data.fields[0].lat_lngs.forEach((latLng: any) => {
        b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
      });
      setDefaultCenter(b.getCenter().toJSON());
      setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));

      db.fields.put({
        id: data.fields[0].uuid,
        bounds: b.toJSON(),
      }).then(() => {});
    } else {
      setCurrentAnalysis(null);
      setDefaultCenter(undefined);
      setDefaultZoom(undefined);
    }
  }, [data]);

  if (!defaultCenter) {
    return (
      <Loading open />
    );
  }

  return (
    <MapFull
    defaultCenter={defaultCenter}
    defaultZoom={defaultZoom}>
      { currentAnalysis && <AnalysisLayer analysisID={currentAnalysis.uuid} image={image} png /> }
      <UserPosition />
      <BackButton />
      <Fab
      onClick={() => setShapeFileDialogOpen(true)}
      style={{
        position: 'absolute',
        bottom: '30px',
        right: '10px',
        zIndex: 0,
      }} color="primary">
        <ShareIcon />
      </Fab>
      <ShapeFileDialog open={shapeFileDialogOpen} onClose={() => setShapeFileDialogOpen(false)} nZones={5} />
    </MapFull>
  );
}

export default Zonation;
