import { GLB_FILE, IMAGE_GNDVI_TIFF, IMAGE_GNDVI_VINEYARD_TIFF, IMAGE_NDREI_TIFF, IMAGE_NDREI_VINEYARD_TIFF, IMAGE_NDVI_TIFF, IMAGE_NDVI_VINEYARD_TIFF, IMAGE_ORTHOPHOTO } from "../common/hooks/useCache";
import { Index } from "../common/utils/indexes";

export let globalAvailableIndexes: Index[] = [
  { id: 'None', name: 'No Index', description: null, showAlways: true },
  { id: 'Orthophoto', name: 'Orthophoto', description: 'Real Colors Field', imageKey: IMAGE_ORTHOPHOTO },
  { id: 'NDVI', name: 'NDVI', description: 'Vigor Level', imageKey: IMAGE_NDVI_TIFF },
  { id: 'NDVI - VINEYARD', name: 'NDVI - Vineyard', description: 'Vigor Level', imageKey: IMAGE_NDVI_VINEYARD_TIFF },
  // { id: 'GNDVI', name: 'GNDVI', description: 'Biomass Level', imageKey: IMAGE_GNDVI_TIFF },
  // { id: 'GNDVI - VINEYARD', name: 'GNDVI - Vineyard', description: 'Biomass Level', imageKey: IMAGE_GNDVI_VINEYARD_TIFF },
  { id: 'NDREI', name: 'NDREI', description: 'Chlorophyll Level', imageKey: IMAGE_NDREI_TIFF },
  { id: 'NDREI - VINEYARD', name: 'NDREI - Vineyard', description: 'Chlorophyll Level', imageKey: IMAGE_NDREI_VINEYARD_TIFF },
  { id: 'GLB', name: '3D', description: '3D Model', imageKey: GLB_FILE, noCompare: true }
];

export function getIndex(id?: string): Index | undefined {
  if (!id) {
    return undefined;
  }

  for (const index of globalAvailableIndexes) {
    if (index.id === id) {
      return index;
    }
  }
  return undefined;
}
