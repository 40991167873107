import { Button, Dialog, DialogActions, DialogContent, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import EditIcon from '@mui/icons-material/Edit';
import ExtensionIcon from '@mui/icons-material/Extension';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import Loading from "../common/components/Loading";

export interface ActionsDialogProps {
  zonationAvailable?: boolean;
  setZonationDialogOpen: (open: boolean) => void;
  open: boolean;
  onClose: () => void;
  downloadMap?: () => Promise<void>;
}

const ActionsDialog = (props: ActionsDialogProps) => {
  const [ loading, setLoading ] = React.useState<boolean>(false);

  const { t } = useTranslation();

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={props.open}
      onClose={() => {props.onClose()}}
      PaperProps={{
        component: 'form',
        onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
          event.preventDefault();
          const formData = new FormData(event.currentTarget);
          const formJson = Object.fromEntries((formData as any).entries());
          props.onClose();
        },
      }}
    >
      <DialogContent style={{
        margin: 0,
        paddingLeft: 10,
        paddingRight: 10,
      }}>
        <Loading open={loading} />
        <MenuList>
          <MenuItem disabled={!props.downloadMap} onClick={async () => {
            if (!props.downloadMap) return
            setLoading(true);
            await props.downloadMap();
            setLoading(false);
          }}>
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText>
              {t('Download Map')}
            </ListItemText>
          </MenuItem>
          <MenuItem disabled>
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText>
              {t('Add Note')}
            </ListItemText>
          </MenuItem>
          <MenuItem disabled={!props.zonationAvailable} onClick={() => props.setZonationDialogOpen(true)}>
            <ListItemIcon>
              <ExtensionIcon />
            </ListItemIcon>
            <ListItemText>
              {t('Prescription Map')}
            </ListItemText>
          </MenuItem>
          <MenuItem disabled>
            <ListItemIcon>
              <AgricultureIcon />
            </ListItemIcon>
            <ListItemText>
              {t('Add Activity')}
            </ListItemText>
          </MenuItem>
        </MenuList>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {props.onClose()}}>{t('Cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionsDialog;
