import { Avatar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import { useAuth, withAuthenticationRequired } from "react-oidc-context";
import { stringAvatar } from "../common/utils/avatar";
import LogoutIcon from '@mui/icons-material/Logout';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SettingsIcon from '@mui/icons-material/Settings';
import MemoryIcon from '@mui/icons-material/Memory';
import CardButton from "../common/components/CardButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { humanFileSize } from "../common/utils/text";
import Iubenda from "../common/components/Iubenda";
import useEmbed from "../common/hooks/useEmbed";

function User() {
  const [cacheSize, setCacheSize] = React.useState(0);

  const auth = useAuth();
  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  const { t } = useTranslation();

  const clearCache = () => {
    if (caches) {
      caches.keys().then(cacheNames => {
        cacheNames.forEach(cacheName => {
          caches.delete(cacheName);
        });
      });

      window.location.reload();
    }
  };

  React.useEffect(() => {
    if (caches) {
      caches.keys().then(cacheNames => {
        let size = 0;
        cacheNames.forEach(cacheName => {
          caches.open(cacheName).then(cache => {
            cache.keys().then(keys => {
              keys.forEach(request => {
                cache.match(request).then(response => {
                  if (response) {
                    response.arrayBuffer().then(buffer => {
                      size += buffer.byteLength;
                      setCacheSize(size);
                    });
                  }
                });
              });
            });
          });
        });
      });
    }
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Box style={{
            marginTop: 20,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Avatar {...stringAvatar(auth.user?.profile.name ?? auth.user?.profile.email ?? t('User'))} src="/imgs/mock/user.png" style={{
              width: 130,
              height: 130,
              fontSize: 50,
            }} />
          </Box>
          <Box style={{
            marginTop: 20,
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
            <Typography variant="h4">
              {auth.user?.profile.name ?? auth.user?.profile.email ?? t('User')}
            </Typography>
            <Typography variant="h5" style={{
              color: 'var(--gray-2)'
            }}>
              {t('Farmer')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box style={{
            margin: 20,
          }}>
            <a href="https://identity.dromt.it/realms/Dromt/account/" target="_blank" rel="noreferrer" role="noreferrer">
              <CardButton text={t("Information")} icon={<AssignmentIndIcon />} showArrow />
            </a>
            <CardButton text={t("Settings")} icon={<SettingsIcon />} showArrow />
            { /* TODO: move to settings page */ }
            <CardButton text={t("Clear Cache")} secondaryText={humanFileSize(cacheSize)} secondaryTextPosition="right" icon={<MemoryIcon />} onClick={clearCache} />
            <Iubenda />
            <CardButton text={t("Logout")} icon={<LogoutIcon />} onClick={() => {
              auth.signoutSilent().then(() => {
                navigate(appendParams('/'));
              });
            }} />
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default withAuthenticationRequired(User);
